.pages {
    @include lib-pager();

    .action {
        width: 25px;
        &:before {
            font-weight: 900 !important;
        }
        &.previous {
            margin-right: 0;
        }

        &.next {
            margin-left: 0;
        }
    }
}
