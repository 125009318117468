//
//  Product Lists
//  _____________________________________________

.page-products {
  .page-title {
    @include flexbox;
    font-size: 18px;
    font-weight: $font-weight__semibold;
    text-transform: uppercase;

    &:after {
      content: '';
      @include flex(1);
      margin: auto;
      height: 0;
      border-top: solid $color-main 3px;
    }
  }
}

.products {
  margin: $indent__l 0;
  .mode-filter-mobile {
    text-align: center;
    display: block;
  }
}

.product {
  &-items {
    @extend .abs-reset-list;
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  &-item {
    @extend .abs-add-box-sizing;
    vertical-align: top;
    position: relative;
    padding-left: 2px !important;
    padding-right: 2px !important;
    .product-item-inner {
      @include flexbox;
      @include flex(3);
      @include flex-wrap(wrap);
      min-height: 85px;

      .price-box {
        @include flex-basis(0);
        @include flex-grow(2);
        @include align-self(flex-end);
        margin-bottom: 1rem;
      }

      .product-item-actions {
        .actions-primary {
          @include flexbox;
          @include align-items(center);
        }

        @include flex-basis(0);
        @include flex-grow(1);
        @include align-self(flex-end);

        .qty-box {
          .ui-spinner {
            @include flexbox;
            @include flex(5);
            @include align-items(center);
            background: $color-gray06;
            text-align: center;
            .ui-icon {
              display: none;
            }
            input {
              width: 60%;
              @include flex-order(2);
              background: $color-gray06;
              text-align: center;
              border: none;
            }
            .ui-spinner-button {
              .ui-button-text {
                &:before {
                  @extend %fa-icon;
                  @extend .fas;
                  color: $color-gray1;
                }
              }
            }
            .ui-spinner-down {
              .ui-button-text {
                &:before {
                  content: fa-content($fa-var-minus);
                }
              }
            }
            .ui-spinner-up {
              .ui-button-text {
                &:before {
                  content: fa-content($fa-var-plus);
                }
              }
            }

            .ui-spinner-up {
              width: 20%;
              @include flex-order(3);
            }

            .ui-spinner-down {
              width: 20%;
              @include flex-order(1);
            }

            i {
              font-size: 1rem;
            }
          }
        }

        button {
          text-transform: uppercase;
          width: 100%;
        }
      }

      .related {
        @include flexbox;
        @include align-items(center);
        padding-bottom: 10px;

        .label {
          padding-left: 5px;
        }
      }
    }

    .products-grid & {

    }

    .sku {
      font-size: 1.2rem;
      color: $color-gray07;
    }

    &-name {
      @extend .abs-product-link;
      display: block;
      margin: 0;
      text-transform: uppercase;
      word-wrap: break-word;
      hyphens: auto;
      min-height: 4rem;
    }

    &-info {
      max-width: 100%;
      border: 1px solid $color-gray09;
      padding: 10px;
      margin-bottom: 4px;
      .page-products & {

      }
    }

    &-actions {
      .actions-primary {
        button {
          text-transform: uppercase;
          border-radius: 0;
        }

        .action {
          &.primary {
            padding: 7px;
          }
        }
      }

      .actions-secondary {
        & > .action {
          @extend .abs-actions-addto-gridlist;


          &:before {
            margin: 0;
          }

          span {
            @extend .abs-visually-hidden;
          }
        }
      }
    }

    &-description {
      margin: $indent__m 0;
      @include flex-basis(100%);

    }

    .product-reviews-summary {
      .rating-summary {
        margin: 0 4px 0 0;
      }

      .reviews-actions {
        font-size: $font-size__s;
        margin-top: 5px;
        text-transform: lowercase;
      }
    }

    .price-box {
      margin: $indent__s 0 $indent__m;

      .price {
        font-size: 22px;
        color: $color-main;
        font-weight: $font-weight__bold;
        white-space: nowrap;
      }

      .price-label {
        display: none;

        &:after {
          content: ':';
        }
      }
    }

    .special-price,
    .minimal-price {
      .price {
        font-size: 15px;
        font-weight: $font-weight__bold;
      }

      .price-wrapper {
        display: inline-block;
      }

      .price-including-tax + .price-excluding-tax {
        display: block;

        .price {
          color: $color-gray07;
        }
      }
    }

    .special-price {
      display: block;

      .price-including-tax {
        .price {
          font-size: 20px;
        }
      }

      .price-excluding-tax {
        position: absolute;
        font-weight: $font-weight__light;
        top: 35px;
        right: 15px;
        z-index: 5;

        .price {
          font-size: 12px;
          font-weight: $font-weight__light;
        }
      }
    }

    .old-price {
      color: $color-gray07;

      .price {
        color: $color-gray07;
        font-size: 18px;
        font-weight: $font-weight__regular;
      }
    }

    .minimal-price {
      .price-container {
        display: block;
      }
    }

    .minimal-price-link {
      margin-top: 5px;

      .price-label {
        color: $link__color;
        font-size: 14px;
      }

      .price {
        font-weight: $font-weight__regular;
      }
    }


    .minimal-price-link,
    .price-excluding-tax,
    .price-including-tax {
      display: block;
      white-space: nowrap;
    }

    .price-excluding-tax {
      .price {
        color: $color-gray07;
        font-weight: $font-weight__regular;
      }
    }

    .price-from,
    .price-to {
      margin: 0;
    }

    .action.tocompare {
      @include lib-icon-font-symbol($icon-compare-empty);
    }

    .tocart {
      white-space: nowrap;
      min-width: 120px;
    }
  }
}

.column.main {
  .product {
    &-items {

    }

    &-item {

    }
  }

}

.price-container {
  .price {
    font-size: 14px;
  }

  .price-including-tax + .price-excluding-tax,
  .weee {
    margin-top: $indent__xs;
  }

  .price-including-tax + .price-excluding-tax,
  .weee,
  .price-including-tax + .price-excluding-tax .price,
  .weee .price,
  .weee + .price-excluding-tax:before,
  .weee + .price-excluding-tax .price {
    font-size: 14px;
  }

  .weee {
    &:before {
      content: '(' attr(data-label) ': ';
    }

    &:after {
      content: ')';
    }

    + .price-excluding-tax {
      &:before {
        content: attr(data-label) ': ';
      }
    }
  }
}

.products-list {
  .product {
    &-item {
      display: block;
      width: 100%;
      &-info {
        @include flex();
        @include flex-wrap(wrap);
        display: flex;
        .actions-secondary {
          z-index: 5;
          @include flex-basis(100%);
        }
      }

      &-photo {
        display: inline-block;
        padding: 0 $indent__l $indent__l 0;
      }

      &-details {
        @include flex();
        @include flex-grow(1);
        @include flex-direction(column);
      }

      &-inner {
        .price-box {
          @include flex-order(2);
        }

        .product-item-actions {
          @include flex-order(3);
        }

        .product-item-description {
          @include flex-order(1);
          @include flex-grow(3);
        }
      }
      .special-price {
        .price-excluding-tax {
          right: 10px;
        }
      }
    }
  }

  .product-image-wrapper {
    @extend .abs-reset-image-wrapper;
  }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
  .products-list .product {
    &-item {
      table-layout: fixed;

      &-photo {
        display: block;
        padding: 0 $indent__s $indent__s 0;
        width: 100%;
      }
    }
  }

  .products-grid {
    .product-item {
      margin-bottom: 15px;

      .product-item-info {
        margin-right: 0;
      }
    }
  }

  .product-list {

  }
}

@include min-screen($screen__s) {
  .product {
    &-item {
      .products-grid & {
      }

      &-actions {
        display: block;

        .products-grid & {
          margin: $indent__s 0;
        }

        .actions-primary + .actions-secondary {
          display: table-cell;
          padding-left: 5px;
          white-space: nowrap;
          width: 50%;

          & > * {
            white-space: normal;
          }
        }

        .actions-primary {
          display: table-cell;
        }
      }
    }
  }

  .page-products,
  .page-layout-1column,
  .page-layout-3columns,
  .page-products.page-layout-1column,
  .page-products.page-layout-3columns {
    .products-grid {
      .product-item {

      }
    }
  }
}

//
//  Desktop
//  _____________________________________________


@include max-screen($screen__m) {
  .page-products {
    .products-grid {
      .product-item {
        &:nth-child(2n) {

        }
      }
    }

    .products-list {
      .product {
        &-item {
          @include flex();
          &-info {
            @include flex-grow(1);
            display: block;
            margin-bottom: 15px;
            .actions-secondary {
              display: block;
            }
          }

          &-inner {
            @include flex-direction(column);
            .price-box {
              @include align-self(center);
            }
            .product-item-actions {
              @include align-self(center);
            }
          }
          &-details {
            display: block;
          }
        }
      }
    }

  }
}

@include min-screen($screen__m) {
  .products {
    .mode-filter-mobile {
      display: none;
    }
  }

}
@include screen($screen__m, $screen__l - 1) {
  .page-products {
    .products-grid {
      .product-item {
        &:nth-child(3n) {

        }
      }
    }
  }
}

@include min-screen($screen__l) {
  .page-products {
    .products-grid {
      .product-item {
        &:nth-child(4n) {

        }
      }
    }
  }
}
