#mmm {
  li {
    &.parent {
      &:not(.level-top) {
        a {
          .ui-menu-icon {
            &:after {
              @extend %fa-icon;
              @extend .fas;
            }
          }
        }
      }
    }

    &.parent {
      a {
        &.level-top {
          > .ui-menu-icon {
            &:after {
              @extend %fa-icon;
              @extend .fas;
            }
          }
        }
      }

    }
  }
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

  .navigation ul {
    padding: 0 $layout-indent__width;
  }

  #mmm {
    background: $color-dark01;

    li {
      position: relative;

      &.level0 {
        &.active {
          > .level-top {
            border: none;
            display: inline;
          }
        }

        &.has-active {
          > .level-top {
            border: none;
            display: inline;
          }
        }

        &:not(:first-child) {
          &.active {
            > .level-top {
              color: $color-main;
            }
          }
        }

        &:not(:first-child) {
          &.has-active {
            > .level-top {
              color: $color-main;
            }
          }
        }
      }

      &.parent {
        &:not(.level-top) {
          a {
            @include flexbox();

            .ui-menu-icon {
              @include flex-order(2);
              @include align-self(self-end);

              &:after {
                content: fa-content($fa-var-chevron-right);
                color: inherit;
                font-size: 10px;
              }
            }

            span {
              &:last-child {
                @include flex-order(1);
                @include flex-grow(1);
              }
            }
          }
        }
      }

      &.level-top {
        a {
          &.level-top {
            color: $color-white;
          }
        }

        &.parent {
          a {
            padding-right: 20px;

            &.level-top {
              > .ui-menu-icon {
                position: absolute;
                right: 0;
                top: -17px;

                &:after {
                  content: fa-content($fa-var-chevron-down);
                  color: inherit;
                  font-size: 10px;
                }
              }
            }
          }

        }

        &:first-child {
          background: $color-main;
          padding-right: 15px;
          padding-left: 10px;

          > a {
            padding-right: 30px;
            text-transform: uppercase;

            &.level-top {
              > .ui-menu-icon {
                &:after {
                  font-size: 14px;
                }
              }
            }
          }

        }

        .submenu {
          padding: 10px 0;

          .active {
            > a {
              border-color: $color-main;
            }
          }

          a {
            text-transform: lowercase;
            padding-right: 10px;
            padding-left: 15px;
            font-weight: $font-weight__regular;
          }
        }

      }

      &:not(.full-width) {
        &.level0 {
          > .submenu {
            left: auto !important
          }
        }
      }
    }

  }

}