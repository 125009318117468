.footer {
  .social {
    padding-top: 15px;
    i {
      color: $color-white;
      background: $color-main;
      padding: 10px;
      margin-right: 5px;
      font-size: 20px;
      width: 45px;
      height: 45px;
      display: inline-block;
      text-align: center;
      &:before {
        @extend %fa-icon;
        @extend .fab;
      }
      &:hover {
        color: $color-white;
        background: $color-main;
      }
    }

    .fb {
      i {
        &:before {
          content: fa-content($fa-var-facebook-f);
        }
      }
    }

    .in {
      i {
        &:before {
          content: fa-content($fa-var-linkedin-in);
        }
      }
    }

    .twitter {
      i {
        &:before {
          content: fa-content($fa-var-twitter);
        }
      }
    }

    .instagram {
      i {
        &:before {
          content: fa-content($fa-var-instagram);
        }
      }
    }

    .yt {
      i {
        &:before {
          content: fa-content($fa-var-youtube);
        }
      }
    }

    .pinterest {
      i {
        &:before {
          content: fa-content($fa-var-pinterest-p);
        }
      }
    }

    .vimeo {
      i {
        &:before {
          content: fa-content($fa-var-vimeo-v);
        }
      }
    }
  }
}

@include max-screen($screen__l) {
  .footer {
    .social {
      padding-bottom: 30px;
      text-align: center;
    }
  }
}

