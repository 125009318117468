.news-container {
  h3 {
    @include flexbox();
    @include flex-grow(1);
    margin-top: 0;
    margin-bottom: 45px;
    font-size: 24px;
    font-weight: $font-weight__semibold;
    text-transform: uppercase;
    &:after {
      content: '';
      @include flex(1);
      margin: auto;
      height: 0;
      border-top: solid $color-gray05 1px;
    }
    span {
      padding-right: 15px;
    }
  }
  .news {
    padding-left: 0;
    padding-bottom: 15px;
  }
  .news-text {
    font-weight: $font-weight__light;
  }
  .news-img {
    img {
      width: 100%;
    }
  }
  h4 {
    text-transform: uppercase;
  }
  a {
    &.readmore {
      @extend .transition-all;
      display: inline-block;
      margin-top: 15px;
      font-weight: $font-weight__regular;
      line-height: 14px;
      border: 1px solid $color-gray05;
      color: $color-gray07;
      padding: 8px 15px;
      text-transform: uppercase;
      &:hover {
        color: $color-white;
        background: $color-gray07;
      }
    }
  }
}

@include min-screen($screen__s) {
  .news-container {
    h4 {
      margin-top: 0;
    }
    .news {
      padding-left: 15px;
    }
  }
}

@include min-screen($screen__m) {
  .news-container {
    h4 {
      margin-top: 2rem;
    }
    .news {
      padding-left: 0;
      padding-right: 15px;
    }
  }
}

@include min-screen($screen__l) {
  .news-container {
    h4 {
      margin-top: 0;
    }
    .news {
      padding-left: 15px;
    }
  }
}
