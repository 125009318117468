.wishlist {
  &.custom {
    display: block;
    float: right;
    &:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 70px;
      line-height: 35px;
      color: $color-black;
      content: $icon-wishlist-empty;
      font-family: "icons-blank-theme";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
    }
    .wishlist-right {
      display: inline-block;
      .text {
        color: $color-gray1;
        text-transform: uppercase;
        display: block;
        clear: both;
      }
      .counter {
        font-weight: $font-weight__semibold;
        color: $color-black;
        display: block;
        clear: both;
      }
    }
  }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
  .wishlist {
    &.custom {
      display: none;
    }
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

}