
.transition-all {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.magento__dropdown-widget {
  @include lib-dropdown();
}

.message.global.cookie {
  z-index: 9999 !important;
  text-align: center;
}

.field-tooltip .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.fieldset > .field.no-label > .label,
.fieldset > .fields > .field.no-label > .label,
.product-item-actions .actions-secondary > .action span,
.table-comparison .cell.label.remove span,
.table-comparison .cell.label.product span,
.cart.table-wrapper .col.qty .label,
.minicart-wrapper .action.showcart .text,
.minicart-wrapper .action.showcart .counter-label,
.checkout-index-index .page-title-wrapper,
.checkout-payment-method .field-select-billing > .label,
.checkout-payment-method .payments .legend,
.checkout-payment-method .ccard .legend,
.fieldset .fullname.field > .label,
.account .column.main .limiter > .label,
.field.street .field .label,
.account-nav .title,
.page-product-downloadable .product-options-wrapper .fieldset .legend.links-title,
.page-product-giftcard .fieldset.giftcard > .legend,
.opc-wrapper .form-giftcard-account .field .label,
.filter-options-content .filter-count-label,
.block-wishlist-search-form .form-wishlist-search .fieldset > .legend,
.page-multiple-wishlist .page-title-wrapper .page-title,
.multicheckout .table-wrapper .col .label,
.multicheckout .block .methods-shipping .item-content .fieldset > .legend,
.block.newsletter .label,
.block-reorder .product-item .label,
.opc-wrapper .form-discount .field .label,
.action.skip:not(:focus),
.page-header .switcher .label,
.page-footer .switcher .label,
.products-grid.wishlist .product-item .comment-box .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.stock {
  text-align: right;

  &-name {

  }

  &-qty {
    min-width: 35px;
    display: inline-block;
    font-weight: $font-weight__semibold;
  }
}

@include min-screen($screen__m) {
  .page-header {
    .switcher {
      .options {
        .action.toggle {
          &:after {
            line-height: 54px;
          }
          &.active {
            &:after {
              line-height: 54px;
            }
          }
        }
      }
    }
  }
}