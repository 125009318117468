.formwidget {
  &.field {
    clear: both;
  }
  &.modal {
    display: none;
  }
}

.product-info-main,
.product-options-bottom,
.block-bundle-summary {
  .action.form-widget {
    font-size: 14px;
    line-height: inherit;
    font-weight: $font-weight__light;
    vertical-align: top;
    background: transparent;
    border: none;
    color: $color-gray07;

    &:hover {
      background: transparent;
      border: none;
      color: $color-gray07;
    }

    &:before {
      @extend %fa-icon;
      @extend .fas;
      color: $color-black;
      content: fa-content($fa-var-envelope);
      padding-right: 10px;
    }
  }
}