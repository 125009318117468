//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background: $color-yellow-light2 !default;
$message-global-note__border-color: $color-yellow-light3 !default;
$message-global-note__color: $text__color !default;

$message-global-note-link__color: $link__color !default;
$message-global-note-link__color-hover: $link__hover__color !default;
$message-global-note-link__color-active: $link__active__color !default;

$message-global-caution__background: $color-red9 !default;
$message-global-caution__border-color: none !default;
$message-global-caution__color: $color-white !default;

$message-global-caution-link__color: $link__color !default;
$message-global-caution-link__color-hover: $link__hover__color !default;
$message-global-caution-link__color-active: $link__active__color !default;

//  Header
$header__background-color: false !default;
$header-icons-color: $color-gray56 !default;
$header-icons-color-hover: $color-gray20 !default;

$addto-color: $text__color__muted !default;
$addto-hover-color: $primary__color !default;

$minicart-icons-color: $header-icons-color !default;
$minicart-icons-color-hover: $header-icons-color-hover !default;

$button__shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

$h1__margin-bottom__desktop: $indent__xl !default;

//  Footer
$footer__background-color: false !default;


body {
  @include lib-css(background-color, $page__background-color);
}


.page-title-wrapper {
  &:not(.product) {
    .page-title {
      @include flexbox;
      font-size: 18px;
      font-weight: $font-weight__semibold;
      text-transform: uppercase;

      &:after {
        content: '';
        @include flex(1);
        margin: auto;
        height: 0;
        border-top: solid $color-main 3px;
      }
    }
  }
}

img.lazy[src=""] {
  -webkit-transition: opacity 500ms ease-in-out;
  -moz-transition: opacity 500ms ease-in-out;
  -o-transition: opacity 500ms ease-in-out;
  transition: opacity 500ms ease-in-out;
  max-width: 100%;
  opacity: 0;
}
img.lazy:not([src=""]) {
  opacity: 1;
}

.loading {
  background: #fff url('../images/ajax-loader.gif') center center no-repeat;
}


.info-text {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border: 1px solid #faebcc;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 15px;
  text-align: justify;
  font-weight: $font-weight__light;
}


//
//  Header
//  ---------------------------------------------

.page-header {
  @include lib-css(background-color, $header__background-color);
  border-bottom: 1px solid $border-color__base;
  margin-bottom: $indent__base;
}

.header {
  &.content {
    @extend .abs-add-clearfix;
    padding-top: $indent__s;
    position: relative;
  }

  .top-info {
    &-text {
      color: $color-gray1;
      font-weight: $font-weight__light;
      text-transform: uppercase;
      font-weight: normal;
      padding-right: 10px;
    }

    .phone {
      font-weight: $font-weight__semibold;
      color: $color-black;
      padding-right: 10px;
    }

    .email {
      font-weight: $font-weight__semibold;
      color: $color-black;
    }
  }

}

.logo {
  float: left;
  margin: 0 0 $indent__s $indent__xl;
  max-width: 50%;
  position: relative;
  z-index: 5;

  img {
    display: block;
  }

  .page-print & {
    float: none;
  }
}

.page-main {
  > .page-title-wrapper {
    border-top: 1px solid $color-gray05;
    padding-top: 30px;

    .page-title + .action {
      margin-top: $indent__l;
    }
  }
}

.action.skip {
  &:not(:focus) {
    @extend .abs-visually-hidden;
  }

  &:focus {
    @include lib-css(background, $color-gray94);
    @include lib-css(padding, $indent__s);
    box-sizing: border-box;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 15;
  }
}

.action-skip-wrapper {
  height: 0;
  position: relative;
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
  p {
    margin: 0;
  }

  &.noscript,
  &.cookie {
    @include lib-message($_message-type: global-note);
    margin: 0;
  }

  &.cookie {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 3;

    .actions {
      margin-top: $indent__s;
    }
  }

  &.demo {
    @include lib-message($_message-type: global-caution);
    margin-bottom: 0;
    text-align: center;
  }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
  @include lib-css(background-color, $footer__background-color);
  margin-top: auto;
}

.newsletter-container {
  background: $color-main;
  color: $color-white;
  min-height: 65px;

  .row {
    align-items: center;

    .newsletter-left {
      font-weight: $font-weight__light;
      text-align: center;
      padding-top: 15px;

      .title {
        font-size: 18px;
        font-weight: $font-weight__semibold;
        text-transform: uppercase;
        padding-right: 5px;
        &:before {
          @extend %fa-icon;
          @extend .fas;
          content: fa-content($fa-var-paper-plane);
          padding-right: 15px;

        }
      }
    }

  }
}

.footer {
  .info {
    padding-top: 20px;

    .address-block {
      padding-bottom: 10px;

      .item {
        &.title {
          font-size: 18px;
          color: $color-main;
          text-transform: uppercase;
          font-weight: $font-weight__semibold;
        }

        &.content {
          padding-top: 5px;

          li {
            position: relative;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 30px;
            color: $color-gray1;

            i {
              &:before {
                @extend %fa-icon;
                @extend .fas;
                color: $color-main;
                position: absolute;
                left: 0;
                top: 9px;
              }
            }

            &.address {
              padding-bottom: 0;

              i {
                &:before {
                  content: fa-content($fa-var-map-marker-alt);
                }
              }
            }

            &.phone {
              padding-bottom: 0;

              i {

                &:before {
                  @include fa-icon-flip(-1, 1, 0);
                  content: fa-content($fa-var-phone);
                }
              }

            }

            &.email {
              i {
                &:before {
                  content: fa-content($fa-var-envelope);
                }
              }
            }
          }
        }
      }
    }

    .collapsible-block {
      @include lib-data-accordion__base();

      .item {
        &.title {
          font-size: 18px;
          color: $color-dark01;
          text-transform: uppercase;
          font-weight: $font-weight__semibold;
          border-top: 1px solid $color-gray04;
          padding-top: 5px;
          padding-bottom: 5px;

          &:before {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            line-height: inherit;
            color: inherit;
            font-size: 30px;
            content: $icon-pointer-down;
            font-family: "icons-blank-theme";
            vertical-align: middle;
            display: inline-block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
            position: absolute;
            right: 0;
            top: -5px;
          }
        }

        &.content {
          padding-top: 5px;

          li {
            padding-top: 5px;
            padding-bottom: 5px;

            a {
              color: $color-gray1;
            }
          }
        }
      }

      &.active {
        .item {
          &.title {
            &:before {
              content: $icon-pointer-up;
            }
          }
        }
      }
    }
  }

  ul {
    @extend .abs-reset-list;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
  }

  .links {
    > li {
      margin: 0 0 8px;
    }
  }

  &.content {
    padding-bottom: 25px;
    padding-top: 25px;

    .switcher-store {
      margin: 0 0 30px;
    }
  }

  .copyright,
  .bugs {
    display: block;
    text-align: center;
    color: $color-gray1;
    background: $color-white;
    padding: 20px 0;
  }
}

.page-header,
.page-footer {
  .switcher {
    margin-right: 10px;

    .options {
      @include lib-dropdown(
              $_dropdown-actions-padding: 0,
              $_dropdown-list-item-padding: 0,
              $_dropdown-toggle-icon-content: $icon-down,
              $_dropdown-toggle-active-icon-content: $icon-up,
              $_icon-font-text-hide: true,
              $_icon-font-size: 22px,
              $_icon-font-line-height: 22px,
              $_dropdown-list-min-width: 160px
      );

      ul.dropdown {
        a {
          display: block;
          padding: 8px;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    li {
      font-size: $font-size__s;
      margin: 0;
    }

    .label {
      @extend .abs-visually-hidden;
    }

    strong {
      font-weight: $font-weight__regular;
    }
  }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
  .widget.block:not(:last-child),
  .widget:not(:last-child) {
    @extend .abs-margin-for-blocks-and-widgets;
  }
}

.widget {
  clear: both;

  .block-title {
    @extend .abs-block-title;
  }
}

.page-header,
.page-footer {
  .widget.block {
    @include lib-css(margin, $indent__base 0);
  }
}

.no-display {
  @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
  td {
    padding: 0;
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

  html,
  body {
    height: 100%; // Stretch screen area for sticky footer
  }

  .page-wrapper {
    @include lib-vendor-prefix-display(flex);
    @include lib-vendor-prefix-flex-direction(column);
    min-height: 100%; // Stretch content area for sticky footer

    > .breadcrumbs,
    > .top-container,
    > .widget {
      box-sizing: border-box;
      width: 100%;
    }

    .ie10 &,
    .ie11 & {
      height: 100%;
    }
  }

  .header {
    &.panel {
      .header.links {
        float: right;
        font-size: 0;
        @include lib-list-inline();
        margin-left: auto;

        > li {
          font-size: $font-size__base;
          margin: 0 0 0 15px;
          text-transform: uppercase;
          font-weight: $font-weight__semibold;

          &.welcome,
          a {
            line-height: 1.4;
            color: $color-black;
          }

          &.welcome {
            a {
              @include lib-css(padding-left, $indent__xs);
            }
          }

          a {
            &.my-account-link {
              &:before {
                font-family: $icomoon-font-family;
                font-size: inherit;
                content: $icon-zeta-user;
                color: $color-main;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                padding-right: 10px;
              }
            }

          }

          &.wishlist {
            display: none;
          }
        }
      }
    }

    &.content {
      @extend .abs-add-clearfix-desktop;
      padding: $indent__l $layout-indent__width 0;
    }

  }

  .page-header {
    border: 0;
    margin-bottom: 0;

    .panel.wrapper {
      border-bottom: 1px solid $secondary__color;
    }

    .header.panel {
      @extend .abs-add-clearfix-desktop;
      padding-bottom: $indent__s;
      padding-top: $indent__s;
    }

    .switcher {
      display: inline-block;
    }
  }

  .page-main {
    > .page-title-wrapper {
      .page-title {
        @include flexbox;

        span {
          padding-right: 1rem;
        }
      }

      .page-title + .action {
        float: right;
        margin-top: $indent__base;
      }
    }
  }

  .logo {
    margin: -8px auto 25px 15px;

    img {
      max-height: inherit;
    }
  }


  .footer {
    .info {
      padding: 40px $layout-indent__width;

      .address-block {
        .item {
          &.content {
            padding-top: 40px;

            li {
              padding-top: 5px;
              padding-bottom: 15px;
            }
          }
        }
      }

      .collapsible-block {
        @include lib-data-accordion__base();

        .item {
          &.title {
            font-size: 18px;
            color: $color-dark01;
            text-transform: uppercase;
            font-weight: $font-weight__semibold;
            border: none;
            padding-top: 0;
            padding-bottom: 0;

            &:before {
              content: '';
            }
          }

          &.content {
            padding-top: 40px;

            li {
              padding-top: 5px;
              padding-bottom: 15px;
            }
          }
        }

        &.active {
          .item {
            &.title {
              &:before {
                content: '';
              }
            }
          }
        }
      }
    }

    &.content {
      .block {
        float: right;
      }

      .links {
        display: inline-block;
        margin-bottom: 20px;
        padding: 0 50px 0 0;
        vertical-align: top;
      }

      .switcher.store {
        display: inline-block;
        padding-right: 50px;
        vertical-align: top;
      }
    }

    .copyright {
      @extend .abs-add-clearfix-desktop;
    }
  }

  .newsletter-container {
    .row {
      .newsletter-left {
        text-align: left;
        padding-top: 0;
      }
    }
  }

  .page-layout-2columns-left .sidebar-additional, .page-layout-2columns-right .sidebar-additional {
    width: 25%;
  }

  .page-layout-2columns-left .sidebar-main, .page-layout-2columns-right .sidebar-main, .page-layout-3columns .sidebar-main {
    width: 25%;
  }

  .page-layout-2columns-left .column.main, page-layout-2columns-right .column.main  {
    width: 75%;
  }
}
