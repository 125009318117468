//
//  Typography
//  ---------------------------------------------

$font-family-name__base: 'FiraSans';
//
//  Colors
//  ---------------------------------------------

$color-main: #3e9ac9;

$color-avail: #64b74f;