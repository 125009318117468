.product-tabs-container {
  &-inner {
    position: relative;
    clear: both;
    min-height: 475px;

    .product-tabs-list {
      @include flexbox();
      @include align-items(center);
      margin-bottom: 50px;

      h3 {
        @include flexbox();
        @include flex-grow(1);
        margin-top: 0;
        margin-bottom: 0;
        font-size: 24px;
        font-weight: $font-weight__semibold;
        text-transform: uppercase;

        &:after {
          content: '';
          @include flex(1);
          margin: auto;
          height: 0;
          border-top: solid $color-gray05 1px;
        }

        span {
          padding-right: 15px;
        }
      }

      .product-tabs {
        list-style: none;
        padding-left: 15px;
        margin-bottom: 0;
        text-align: right;

        li {
          display: inline-block;
          text-transform: uppercase;
          font-weight: $font-weight__semibold;
          margin-bottom: 0;
          padding: 5px 10px;
          background: $color-dark01;

          &.active {
            background: $color-main;
          }

          a {
            color: $color-white;
          }

        }

      }

    }

    &.loading {
      //@include lib-loader();
      position: relative;
    }

    .content {
      @extend .transition-all;
      margin-right: 1px;
      .product-item {
        &-info {
          margin-right: 0;
          border: none;
          padding: 15px;

          .actions-secondary {
            overflow: hidden;
            min-height: 25px;
          }

          .special-price {
            .price-excluding-tax {
              top: 40px;
              right: 15px;
            }
          }
        }

        &.slick-slide {
          border-right: 1px solid $color-gray05;
        }
      }
    }
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }
}

@include max-screen($screen__s) {
  .product-tabs-container {
    &-inner {
      .product-tabs-list {
        display: block;
        h3 {

        }
        .product-tabs {
          padding-top: 20px;
          text-align: center;
        }
      }
    }
  }
}