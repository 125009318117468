.banners-container {
  margin-top: 55px;
  margin-bottom: 50px;
  .banner {
    position: relative;
    .text {
      position: absolute;
      left: 30px;
      top: 20%;

      span {
        display: block;
        color: $color-dark01;

        &.head {
          text-transform: uppercase;
        }

        &.head2 {
        }
      }
    }


    img {
      width: 100%;
      display: block;
    }

    i {
      @extend %fa-icon;
      @extend .fas;
      font-size: 30px;
      color: $color-main;
      position: absolute;
      left: 30px;
      bottom: 15%;

      &:before {
        content: fa-content($fa-var-chevron-circle-right);
      }
    }

    &-1 {
      .text {
        span {
          &.head {
            font-weight: $font-weight__light;
          }

          &.head2 {
            font-size: 24px;
            font-weight: $font-weight__semibold;
          }
        }
      }
    }

    &-2 {
      .text {
        span {
          &.head {
            font-size: 16px;
          }

          &.head2 {
            color: $color-main;
            font-size: 30px;
            font-weight: $font-weight__bold;
            text-transform: uppercase;
          }
        }
      }
    }

    &-3 {
      .text {
        span {
          color: $color-white;
          text-transform: uppercase;

          &.head {
            font-size: 22px;
            font-weight: $font-weight__semibold;
          }

          &.head2 {
            font-size: 18px;
            font-weight: $font-weight__light;
          }
        }
      }

      i {
        color: $color-white;
      }
    }
  }

}