.breadcrumbs {
    @include lib-breadcrumbs();
    padding-bottom: 5px;
    .item {
         &:last-child {
            text-transform: uppercase;
        }
    }
}

@include min-screen($screen__m) {
    .breadcrumbs {
        padding-bottom: 20px;
        margin-bottom: 0;
    }
}
