//
//  Colors
//  ---------------------------------------------

$color-blue1: $color-main;
$color-blue2: $color-main;
$color-blue3: $color-main;

$link__color: $color-gray1;
$link__visited__color: $color-gray07;
$link__hover__color: $color-gray07;
$link__active__color: $color-gray07;

$color-orange-red1: $color-main;

$link__hover__text-decoration: none;

//
//  Button
//  ---------------------------------------------

$button-primary__background: $color-main;
$button-primary__border: 1px solid $color-main;
$button-primary__hover__background: $color-main;
$button-primary__hover__border: 1px solid $color-main;
$_button-padding: 7px;

//
//  Navigation
//  ---------------------------------------------

$submenu-desktop__min-width: 280px;
$submenu-desktop-item__padding: 4px $indent__base;
$submenu-item__active__border-color: $color-main;

//
//  Pages
//  ---------------------------------------------

$pager__font-size: 17px;
$pager__font-weight: $font-weight__semibold;
$pager__line-height: 25px;
$pager-item__padding: 0 10px;
$pager-current__border: 1px solid $color-main;
$pager-current__color: $color-main;
$pager-current__font-weight: $font-weight__semibold;
$pager__color: $color-dark01;
$pager__visited__color: $color-dark01;
$pager__hover__color: $color-dark01;
$pager__active__color: $color-dark01;
$pager-action__border: none;
$pager-icon__previous-content: '\f0d9';
$pager-icon__next-content: '\f0da';
$pager-icon__font: 'Font Awesome 5 Free';
$pager-action__color: $color-main;
$pager-action__hover__color: $color-main;
$pager-icon__font-size: 22px;


//
//  Footer
//  ---------------------------------------------

$footer__background-color: $color-gray02;

//
//  Toolbar
//  ---------------------------------------------

$toolbar-mode-icon-font-size: 22px;





