.home-page {
  .slider {
    overflow: hidden;
    display: none;
    img {
      width: 100%;
    }

    .slide {
      position: relative;
      color: $color-gray11;

      .left {
        position: absolute;
        left: 35px;
        top: 15px;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;

        span {
          display: block;
          clear: both;
        }
      }

      .right {
        position: absolute;
        right: 35px;
        top: 15px;
        text-align: right;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;

        span {
          display: block;
          clear: both;
        }
      }

      .big {
        font-size: 25px;
        line-height: 25px;
        font-weight: $font-weight__semibold;
        color: $color-main;
      }

      .big2 {
        font-size: 18px;
        line-height: 18px;
        font-weight: $font-weight__semibold;
      }

      .small {
        font-size: 13px;
        font-weight: $font-weight__light;
        padding: 10px 0;
      }

      a {
        &.readmore {
          @extend .transition-all;
          display: inline-block;
          margin-top: 0;
          font-weight: $font-weight__light;
          line-height: 13px;
          font-size: 13px;
          border: 1px solid $color-gray11;
          color: $color-gray11;
          padding: 5px 10px;
          text-transform: uppercase;

          &:hover {
            color: $color-white;
            background: $color-gray11;
          }
        }
      }

      &.slick-active {
        .left, .right {
          visibility: visible;
          opacity: 1;
          transition: visibility 0s linear 0s, opacity 300ms;
        }
      }
    }
    &.slick-initialized {
      display: block;
    }
  }
}

@include min-screen($screen__xs) {
  .home-page {
    .slider {
      .slide {
        .left {
          left: 35px;
          top: 15px;
        }

        .right {
          right: 35px;
          top: 15px;
        }

        .big {
          font-size: 40px;
          line-height: 40px;
        }

        .big2 {
          font-size: 24px;
          line-height: 24px;
        }

        .small {
          font-size: 14px;
          font-weight: $font-weight__light;
          padding: 10px 0;
        }

        a {
          &.readmore {
            margin-top: 0;
            line-height: 13px;
            font-size: 13px;
            padding: 8px 15px;
          }
        }
      }
    }
  }
}

@include min-screen($screen__m) {
  .home-page {
    .slider {
      .slide {
        .left {
          left: 50px;
          top: 40px;
        }

        .right {
          right: 50px;
          top: 40px;
        }

        .big {
          font-size: 55px;
          line-height: 55px;
        }

        .big2 {
          font-size: 32px;
          line-height: 32px;
        }

        .small {
          font-size: 15px;
          font-weight: $font-weight__light;
          padding: 15px 0;
        }

        a {
          &.readmore {
            margin-top: 10px;
            line-height: 13px;
            font-size: 13px;
            padding: 8px 25px;
          }
        }
      }
    }
  }
}

@include min-screen($screen__l) {
  .home-page {
    .slider {
      .slide {
        .left {
          left: 65px;
          top: 55px;
        }

        .right {
          right: 65px;
          top: 55px;
        }

        .big {
          font-size: 72px;
          line-height: 72px;
        }

        .big2 {
          font-size: 48px;
          line-height: 48px;
        }

        .small {
          font-size: 16px;
          padding: 20px 0;
        }

        a {
          &.readmore {
            margin-top: 15px;
            line-height: 14px;
            font-size: 14px;
            padding: 8px 35px;
          }
        }
      }
      .slick-dots {
        bottom: 15px;

        li {
          height: 20px;
          width: 20px;

          button {
            height: 20px;
            width: 20px;

            &:before {
              width: 20px;
              height: 20px;
              font-size: 20px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}