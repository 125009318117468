//  Newsletter subscription

.block.newsletter {
  margin-bottom: 0;

  .form.subscribe {
    display: table;
    width: 100%;
  }

  .fieldset {
    display: table-cell;
    margin: 0;
    padding: 0;
    vertical-align: top;
  }

  .field {
    margin: 0;

    .control {
      @include lib-icon-font(
                      $icon-envelope,
              $_icon-font-size: 35px,
              $_icon-font-line-height: 40px,
              $_icon-font-color: $form-element-input-placeholder__color
      );
      display: block;
      padding-top: 15px;
      padding-bottom: 15px;
      &:before {
        position: absolute;
      }
      div.mage-error {
        color: $color-white;
      }
    }
  }

  input {
    @include lib-css(border-radius, $button__border-radius);
    padding-left: 35px;
    height: 40px;
    color: $color-gray03;
  }

  .title {
    display: none;
  }

  .label {
    @extend .abs-visually-hidden;
  }

  .actions {
    display: table-cell;
    vertical-align: top;
    width: 1%;

    .primary {
      border-color: transparent;
      background: rgba(0, 0, 0, 0.2);
      padding: 11px 15px;
      &:hover {
        border-color: transparent;
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .action.subscribe {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px;
    margin-top: 15px;
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .block.newsletter {
    width: 80%;
  padding-top: 15px;
  padding-bottom: 15px;
    .field {
      margin-right: 5px;

      .control {
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .action.subscribe {
      border-radius: 3px;
      margin-top: 0;
    }
  }
}
